import React from 'react';
import Table, { CustomOpenSector, CustomTier, CustomTitle, SelectColumnFilter } from '../Table';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "항해도",
        accessor: "GroupNm",
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ value, globalFilter }) => {
          return (
            <div>
              <span className="font-bold text-theme-headerrow">
                {value}
              </span>
            </div>
          );
        }
      },
      {
        Header: "해역",
        accessor: "PrettyNm",
        Cell: ({ value, row, globalFilter }) => {
          const linkText = row.original.Nm;
          return (
            <Link 
              to={`/sector/${row.original.ID}`}
              className="text-blue-600 hover:text-blue-800 hover:underline"
            >
              <CustomTitle value={value} globalFilter={globalFilter} />
            </Link>
          );
        }
      },
      {
        Header: "발견해역",
        accessor: "PrettyOpenSectors",
        disableFilters: true,
        Cell: ({ value, globalFilter }) => {
          const sectors = value ? value.split('\n') : [];
          
          return (
            <div className="text-theme-gray">
              {sectors.map((sector, index) => (
                <div key={index}>
                  <span className="font-bold text-theme-gray">
                    {sector}
                  </span>
                  <br />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        Header: "⭐",
        accessor: "Tier1",
        disableFilters: true,
        Cell: CustomTier,
      },
      {
        Header: "⭐⭐",
        accessor: "Tier2",
        disableFilters: true,
        Cell: CustomTier,
      },
      {
        Header: "⭐⭐⭐",
        accessor: "Tier3",
        disableFilters: true,
        Cell: CustomTier,
      },

    ],
    []
  );

  // 홈페이지 SEO 데이터
  const homeSchemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "잠수함과 심해속으로 - 파판14 잠수함 정보",
    "description": "파판14 잠수함 루트, 잠수함 섹터 정보와 드랍시트를 한눈에! 검은색 점토, 해저 점토, 잿빛 클러스터, 마그네시아 가루 등 희귀 아이템 획득 정보.",
    "url": "https://submarine.girin.dev/",
    "publisher": {
      "@type": "Organization",
      "name": "잠수함과 심해속으로",
      "url": "https://submarine.girin.dev/"
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>잠수함과 심해속으로 - 파판14 잠수함 정보</title>
        <meta name="description" content="파판14 14 잠수함 루트, 잠수함 섹터 정보와 드랍시트를 한눈에! 검은색 점토, 해저 점토, 잿빛 클러스터, 마그네시아 가루 등 희귀 아이템 획득 정보." />
        
        {/* Open Graph 메타 태그 */}
        <meta property="og:title" content="잠수함과 심해속으로 - 파판14 잠수함 정보" />
        <meta property="og:description" content="파판14 잠수함 루트, 잠수함 섹터 정보와 드랍시트를 한눈에! 검은색 점토, 해저 점토, 잿빛 클러스터, 마그네시아 가루 등 희귀 아이템 획득 정보." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://submarine.girin.dev/" />
        
        {/* Twitter 메타 태그 */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="잠수함과 심해속으로 - 파판14 잠수함 정보" />
        <meta name="twitter:description" content="파판14 잠수함 루트, 잠수함 섹터 정보와 드랍시트를 한눈에! 검은색 점토, 해저 점토, 잿빛 클러스터, 마그네시아 가루 등 희귀 아이템 획득 정보." />
        
        {/* 구조화된 데이터 */}
        <script type="application/ld+json">
          {JSON.stringify(homeSchemaData)}
        </script>
        
        {/* 키워드 메타 태그 */}
        <meta name="keywords" content="파판14, 잠수함, 섹터, 드롭 아이템, 검은색 점토, 해저 점토, 잿빛 클러스터, 마그네시아 가루, FF14" />
      </Helmet>
      <Header />
      <div className="flex-grow container mx-auto px-4 py-6">
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};

export default Home;