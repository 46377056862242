import React from 'react';
import { Link } from 'react-router-dom';

const Header = React.memo(() => (
  <header className="bg-theme-headerrow shadow-md w-full">
    <div className="w-full px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
      <div className="flex items-center">
        <Link to="/" className="font-['GmarketSans'] text-white text-xl font-semibold">
          파판 14 잠수함 정보
        </Link>
        <span className="ml-4 px-3 py-1 bg-yellow-400 text-gray-800 rounded-full text-xs font-semibold font-['GmarketSans']">
          7.1 업데이트 완료
        </span>
      </div>
      
      <a 
        className="text-white hover:text-yellow-200 text-sm font-medium font-['GmarketSans'] transition-colors duration-200" 
        href='https://submarine-old.girin.dev/' 
        target='_blank' 
        rel="noreferrer"
      >
        이전버전
      </a>
    </div>
  </header>
));

export default Header;