import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../Header';

const SectorDetail = ({ sectors }) => {
  const { sectorId } = useParams();
  const sector = sectors.find(s => s.ID === sectorId);

  // 광고 초기화
  React.useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('광고 로드 실패:', e);
    }
  }, []);

  if (!sector) {
    return (
      <div className="flex flex-col min-h-screen">
        <Helmet>
          <title>섹터를 찾을 수 없습니다 - 파판14 잠수함과 심해속으로</title>
          <meta name="description" content="요청하신 잠수함 섹터 정보를 찾을 수 없습니다." />
        </Helmet>
        <Header />
        <div className="flex-grow container mx-auto px-4 py-6">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-4">
            <p>섹터를 찾을 수 없습니다.</p>
            <Link to="/" className="text-blue-500 hover:text-blue-700 underline">
              메인 페이지로 돌아가기
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // 원본 드롭 아이템 데이터 사용 (탐사성능 텍스트 제외)
  // RawTier 필드가 있으면 사용하고, 없으면 Tier 필드에서 첫 줄을 제외한 나머지 사용
  const getTierItems = (rawTier, tier) => {
    if (rawTier) return rawTier.split('\n').filter(item => item.trim());
    if (tier) {
      const lines = tier.split('\n');
      return lines.slice(1).filter(item => item.trim()); // 첫 줄(탐사성능 텍스트) 제외
    }
    return [];
  };
  
  const tier1Items = getTierItems(sector.RawTier1, sector.Tier1);
  const tier2Items = getTierItems(sector.RawTier2, sector.Tier2);
  const tier3Items = getTierItems(sector.RawTier3, sector.Tier3);

  // 연결된 섹터 ID를 추출
  const openSectors = sector.OpenSectors 
    ? sector.OpenSectors.split('\n').filter(id => id.trim()) 
    : [];

  // 연결된 섹터 객체를 찾음
  const connectedSectors = openSectors.map(id => 
    sectors.find(s => s.ID === id)
  ).filter(Boolean);

  // SEO를 위한 메타 데이터 생성
  const pageTitle = `${sector.Nm}- 파판14 잠수함과 심해속으로`;
  
  // 드롭 아이템 중 가장 가치 있는 아이템들을 설명에 포함
  const topItems = [...tier3Items, ...tier2Items].slice(0, 3);
  const itemsText = topItems.length > 0 ? `주요 드롭 아이템: ${topItems.join(', ')}` : '';
  
  const pageDescription = `${sector.Nm}는 파이널 판타지 14의 랭크 ${sector.Rank} 잠수함 섹터입니다. ${itemsText} 경험치: ${sector.XP.toLocaleString()}`;
  
  // 연결된 섹터 정보를 설명에 추가
  const connectedSectorsText = connectedSectors.length > 0 
    ? `연결된 섹터: ${connectedSectors.map(s => s.Nm).join(', ')}` 
    : '';
  
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": pageTitle,
    "description": pageDescription,
    "author": {
      "@type": "Organization",
      "name": "파판14 잠수함과 심해속으로"
    },
    "publisher": {
      "@type": "Organization",
      "name": "파판14 잠수함과 심해속으로",
      "url": "https://submarine.girin.dev/"
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://submarine.girin.dev/#/sector/${sector.ID}`
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* Open Graph 메타 태그 */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://submarine.girin.dev/#/sector/${sector.ID}`} />
        
        {/* Twitter 메타 태그 */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* 구조화된 데이터 */}
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        
        {/* 키워드 메타 태그 */}
        <meta name="keywords" content={`파판14, 파이널 판타지 14, 잠수함, ${sector.Nm}, 랭크 ${sector.Rank}, ${topItems.join(', ')}, ${sector.GroupNm}, FF14`} />
      </Helmet>
      
      <Header />
      <div className="flex-grow container mx-auto px-4 py-6">

        <div className="mt-4">
          <Link to="/" className="text-theme-headerrow hover:text-blue-700 font-semibold font-['GmarketSans']">
            &larr; 메인 페이지로 돌아가기
          </Link>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6 mt-4 font-['GmarketSans'] font-medium">
          <div className="flex flex-wrap justify-between items-start">
            <div>
              <h1 className="text-3xl font-semibold text-theme-headerrow">{sector.Nm}</h1>
              <p className="text-lg text-theme-gray font-semibold">{sector.GroupNm}</p>
            </div>
            <div className="bg-theme-headerrow px-4 py-2 rounded-full">
              <span className="text-white font-semibold">랭크 {sector.Rank}</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div>
              <h2 className="text-xl font-semibold text-theme-headerrow mb-3 border-b border-gray-200 pb-2">기본 정보</h2>
              <div className="grid grid-cols-2 gap-2">
                <div className="font-semibold text-theme-gray">경험치</div>
                <div className="text-theme-gray font-semibold">{sector.XP.toLocaleString()}</div>
                
                <div className="font-semibold text-theme-gray">탐사성능 ⭐⭐</div>
                <div className="text-theme-gray font-semibold">{sector.Tier2Surveillance}</div>
                
                <div className="font-semibold text-theme-gray">탐사성능 ⭐⭐⭐</div>
                <div className="text-theme-gray font-semibold">{sector.Tier3Surveillance}</div>
                
                <div className="font-semibold text-theme-gray">행운</div>
                <div className="text-theme-gray font-semibold">{sector.Luck}</div>
              </div>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-theme-headerrow mb-3 border-b border-gray-200 pb-2">발견해역</h2>
              {connectedSectors.length > 0 ? (
                <ul className="space-y-2">
                  {connectedSectors.map(s => (
                    <li key={s.ID}>
                      <Link 
                        to={`/sector/${s.ID}`} 
                        className="text-theme-headerrow hover:text-blue-700 hover:underline font-semibold"
                      >
                        {s.Nm} (랭크 {s.Rank})
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-theme-gray italic">연결된 섹터가 없습니다.</p>
              )}
            </div>
          </div>
        </div>

        {/* 드롭 아이템 섹션 위 광고 */}
        <div className="my-6">
        <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-3428984687301422"
            data-ad-slot="9929916203"
            data-ad-format="auto"
            data-full-width-responsive="true">
          </ins>
        </div>

        <div className="mt-8">
          <div className="bg-white shadow-md rounded-lg p-6 font-['GmarketSans'] font-medium">
            <h2 className="text-2xl font-semibold text-theme-headerrow mb-4 border-b border-gray-200 pb-2">드롭 아이템</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-green-50 p-4 rounded-md shadow-sm">
                <h3 className="text-lg font-semibold mb-3 flex items-center">
                  <span className="text-theme-yellow">⭐</span>
                </h3>
                {tier1Items.length > 0 ? (
                  <ul className="space-y-2">
                    {tier1Items.map((item, index) => (
                      <li key={index} className="text-theme-gray text-sm font-semibold">{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-theme-gray italic">아이템이 없습니다</p>
                )}
              </div>
              
              <div className="bg-blue-50 p-4 rounded-md shadow-sm">
                <h3 className="text-lg font-semibold mb-3 flex items-center">
                  <span className="text-theme-yellow">⭐⭐</span>
                </h3>
                {tier2Items.length > 0 ? (
                  <ul className="space-y-2">
                    {tier2Items.map((item, index) => (
                      <li key={index} className="text-theme-gray text-sm font-semibold">{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-theme-gray italic">아이템이 없습니다</p>
                )}
              </div>
              
              <div className="bg-purple-50 p-4 rounded-md shadow-sm">
                <h3 className="text-lg font-semibold mb-3 flex items-center">
                  <span className="text-theme-yellow">⭐⭐⭐</span>
                </h3>
                {tier3Items.length > 0 ? (
                  <ul className="space-y-2">
                    {tier3Items.map((item, index) => (
                      <li key={index} className="text-theme-gray text-sm font-semibold">{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-theme-gray italic">아이템이 없습니다</p>
                )}
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default SectorDetail;