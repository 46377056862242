import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import SectorDetail from './components/SectorDetail';
import { getData } from './App';

const AppRouter = () => {
  // 섹터 데이터를 App.js에서 가져옴
  const sectors = getData();

  // URL에서 검색어 파라미터 가져오기
  React.useEffect(() => {
    const url = new URL(window.location.href);
    const searchParam = url.searchParams.get('q');
    if (searchParam) {
      // 검색어 처리 로직이 필요하다면 여기에 추가
      console.log("Search param:", searchParam);
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home data={sectors} />
        </Route>
        <Route path="/sector/:sectorId">
          <SectorDetail sectors={sectors} />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;