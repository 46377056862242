import React from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'
import { SortIcon, SortUpIcon, SortDownIcon } from './shared/Icons'

// Highlight function
function highlightText(text, filter) {
  if (!filter || !text || typeof text !== 'string') return text;
  
  const regex = new RegExp(`(${filter})`, 'gi');
  return text.split(regex).map((part, index) => 
    regex.test(part) ? (
      <mark key={index} className="bg-yellow-200">{part}</mark>
    ) : (
      part
    )
  );
}


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(() => {
    // 세션스토리지에서 검색어 가져오기
    return sessionStorage.getItem('searchKeyword') || globalFilter || '';
  });
  
  const onChange = useAsyncDebounce(value => {
    const url = new URL(window.location.href);
    const hash = url.hash;
    
    // 검색어 업데이트
    if (value) {
      url.searchParams.set('q', value);
      // 세션스토리지에 검색어 저장
      sessionStorage.setItem('searchKeyword', value);
    } else {
      url.searchParams.delete('q');
      // 세션스토리지에서 검색어 삭제
      sessionStorage.removeItem('searchKeyword');
    }
    
    // 해시 유지하면서 URL 업데이트
    window.history.pushState({}, '', `${url.pathname}${url.search}${hash}`);
    
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div>
      <label className="flex gap-x-2 items-baseline font-['GmarketSans']">
        <span className="text-theme-gray font-medium">키워드 검색: </span>
        <input
          type="text"
          name="search_term_string"
          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-['GmarketSans']"
          value={value}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} 개의 항목 검색...`}
        />
      </label>
    </div>
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // 초기 필터값 설정
  React.useEffect(() => {
    const savedFilter = sessionStorage.getItem(`filter_${id}`);
    if (savedFilter && savedFilter !== filterValue) {
      setFilter(savedFilter);
    }
  }, [id, setFilter, filterValue]);

  return (
    <label className="flex gap-x-2 items-baseline font-['GmarketSans']">
      <span className="text-theme-gray font-medium">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-['GmarketSans']"
        name={id}
        id={id}
        value={filterValue || ''}
        onChange={e => {
          const value = e.target.value || undefined;
          setFilter(value);
          // 세션스토리지에 필터값 저장
          if (value) {
            sessionStorage.setItem(`filter_${id}`, value);
          } else {
            sessionStorage.removeItem(`filter_${id}`);
          }
        }}
      >
        <option value="">전체</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  )
}

export function CustomTitle({ value, globalFilter }) {
  const titles = value.split('\n');

  return (
    <div key={`${value}_customTitle`}>
      <span className="font-bold text-theme-headerrow">
        {highlightText(titles[0], globalFilter)}
      </span>
      <br></br>
      <span className="text-xs font-semibold text-theme-gray">
        {highlightText(titles[1], globalFilter)}
      </span>
      <br></br>
      <span className="text-xs font-semibold text-theme-gray">
        {highlightText(titles[2], globalFilter)}
      </span>
    </div>
  );
}

export function CustomOpenSector({ value, globalFilter }) {
  const titles = value.split('\n');

  return (
    <div key={`${value}__customOpenSector`} className="text-theme-gray">
    {titles.map(element => 
      <div key={element}>
        <span className="text-theme-gray font-semibold"> 
          {highlightText(element, globalFilter)}
        </span>
        <br></br>
      </div>
    )}
    </div>
  );
}

export function CustomTier({ value, globalFilter }) {
  const titles = value.split('\n');

  return (
    <div className="text-theme-gray">
      <div key={`${titles[0]}_customTier`}>
        <span className="text-theme-yellow text-sm font-semibold"> 
          {highlightText(titles[0], globalFilter)}
        </span>
      </div>
      {
        titles.slice(1).map((x, i) => 
          <div key={`${x}_customTier_child`}>
            <span className="text-theme-gray text-xs font-semibold"> 
              {highlightText(x, globalFilter)}
            </span>
          </div>
        )
      }

    </div>
  );
}

function Table({ columns, data }) {
  const [isInitializing, setIsInitializing] = React.useState(true);
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // URL의 검색어나 세션스토리지의 검색어를 초기값으로 설정
        globalFilter: sessionStorage.getItem('searchKeyword') || 
          new URLSearchParams(window.location.search.split('#')[0]).get('q') || ''
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  // 광고 초기화
  React.useEffect(() => {
    try {
      // 모든 광고 요소 선택
      const adElements = document.querySelectorAll('.adsbygoogle');
      
      // 각 광고 요소 초기화
      adElements.forEach(ad => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error('광고 초기화 실패:', e);
        }
      });
    } catch (e) {
      console.error('광고 초기화 실패:', e);
    }
  }, [rows.length]); // 행 수가 변경될 때마다 광고 다시 초기화

  // 초기화 및 URL 처리
  React.useEffect(() => {
    // URL에서 검색어와 해시 분리
    const [urlPath, hash] = window.location.href.split('#');
    const searchQuery = new URLSearchParams(urlPath.split('?')[1]).get('q');
    
    if (searchQuery) {
      setGlobalFilter(searchQuery);
    }
    
    // 해시 처리 (기존 코드 유지)
    if (hash) {
      const locationName = decodeURIComponent(hash.replace('location-', ''));
      const element = document.querySelector(`[data-location="${locationName}"]`);
      
      if (element) {
        const isAltRow = element.classList.contains("bg-theme-altrow");
        
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
          
          if(isAltRow){
            element.classList.remove('bg-theme-altrow');
          }
          
          element.classList.add('bg-yellow-100');
          
          setTimeout(() => {
            element.classList.remove('bg-yellow-100');
            if(isAltRow){
              element.classList.add('bg-theme-altrow');
            }
          }, 2000);
        }, 500); // 검색 필터가 적용된 후 스크롤하도록 약간의 지연 추가
      }
    }

    setTimeout(() => setIsInitializing(false), 100);
  }, []);

  // 공유 기능 제거됨

  return (
    <div className={`transition-opacity duration-300 ${isInitializing ? 'opacity-0' : 'opacity-100'}`}>

      <div className="sm:flex sm:gap-x-2">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      
      {/* 검색 결과 요약 추가 */}
      {state.globalFilter && (
          <div className="mt-2 text-sm text-gray-600">
            검색어 "{state.globalFilter}"에 대한 결과: {rows.length}건
          </div>
      )}

      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-2xl">
              <table {...getTableProps()} className=" bg-theme-headerrow min-w-full divide-y divide-gray-200">
                <thead className="bg-theme-headerrow">
                  {headerGroups.map(headerGroup => ( 
                    <tr  {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xl pt-4 tracking-wider font-medium bg-theme-headerrow text-theme-headerwhite font-['GmarketSans']"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <SortDownIcon className="w-4 h-4 text-theme-headerwhite" />
                                  : <SortUpIcon className="w-4 h-4 text-theme-headerwhite" />
                                : (
                                  <SortIcon className="w-4 h-4 text-theme-headerwhite opacity-0 group-hover:opacity-100" />
                                )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {rows.map((row, i) => {  
                    prepareRow(row)
                    const locationID = row.original.ID || ''; // title 컬럼에 해역명이 있다고 가정
                    const elements = [];
                    
                    // 일반 행 추가
                    elements.push(
                      <tr
                        key={`row-${i}`}
                        {...row.getRowProps()}
                        className={i % 2 === 0 ? "hover:bg-theme-hover bg-theme-altrow" : "hover:bg-theme-hover"}
                        data-location={locationID}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={"px-6 py-4 whitespace-nowrap content-start"}
                              role="cell"
                            > 
                              {cell.column.Cell.name === "defaultRenderer"
                                ? <div className="text-sm text-theme-gray">{cell.render('Cell')}</div>
                                : cell.render('Cell', { globalFilter: state.globalFilter })}
                            </td>
                          )
                        })}
                      </tr>
                    );

                    // 7개 행마다 광고 행 추가 (마지막 행 제외)
                    if ((i + 1) % 14 === 0 && i !== rows.length - 1) {
                      elements.push(
                        <tr key={`ad-${i}`} className="bg-gray-50">
                          <td colSpan={columns.length} className="px-6 py-4">
                            <ins className="adsbygoogle"
                              style={{ display: 'block', textAlign: 'center' }}
                              data-ad-client="ca-pub-3428984687301422"
                              data-ad-slot="9575558956"
                              data-ad-format="auto"
                              data-ad-layout-key="-6t+ed+2i-1n-4w"
                              data-full-width-responsive="true">
                            </ins>
                          </td>
                        </tr>
                      );
                    }

                    return elements;
                  }).flat()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* 테이블 하단 광고 */}
      <div className="mt-6">
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-3428984687301422"
          data-ad-slot="1697068933"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins>
      </div>
    </div>
  )
}

export default Table;